/* Navbar Styling */
nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; 
  padding: 10px;
  background-color: inherit;
  backdrop-filter: blur(20px);  transition: background-color 0.5s ease;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: white;
  background-color: #00689f64;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #caf0f8;
}

nav a {
  color: #ffffff;
  margin: 0 15px;
  font-size: 1.1rem;
}

nav a:hover {
  color: #ffa500;
}

.nav-links a:last-child {
  margin-right: 2rem;
}

