@font-face {
  font-family: 'Azonix';
  src: url('./assets/fonts/Azonix.otf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins.ttf') format('truetype');
}

@font-face {
  font-family: 'Videophreak';
  src: url('./assets/fonts/Videophreak.ttf') format('truetype');
}

@font-face {
  font-family: 'pdark';
  src: url('./assets/fonts/pdark.ttf') format('truetype');
}

@font-face {
  font-family: 'nasalization';
  src: url('./assets/fonts/nasalization.otf') format('truetype');
}


:root {
  --background-color: #1d1d1d;
  --text-color: #ffffff;
  --highlight-color: #ff6347;
  --nav-footer-bg-color: #333;
}

.light-mode {
  background-color: #f5f5f5; /* Softer off-white background */
  color: #333333; /* Dark gray text for better readability */
}

/* Light Mode Theme for Navbar */
.light-mode nav {
  background-color: #6ca0dc; 
}

/* Light Mode Navigation Links */
.light-mode nav a {
  color: #ffffff; 
}

/* Light Mode Project Card Hover */
.light-mode .project-card:hover {
  background-color: #444; 
  color: #ffffff; 
  transition: background-color 0.3s ease, color 0.3s ease;
}

.light-mode .project-card h3 {
  color: #ff8c42; 
}

.light-mode .project-card a {
  color: #007bff;
}

.light-mode .about-me {
  color: #333; 
}


.chess-theme {
  --background-color: #E9BE77;
  --text-color: #000000;
  --highlight-color: #A87B51;
}


.futuristic-theme {
  --background-color: #000000;
  --text-color: #39ff14;
  --highlight-color: #00ff41;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: all 0.5s ease;
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--highlight-color);
}

nav, footer {
  background-color: var(--nav-footer-bg-color);
  color: var(--text-color);
}

nav a, footer a {
  color: var(--highlight-color);
}

nav a:hover, footer a:hover {
  color: #ff4500;
}


body {
  font-family: 'Poppins', sans-serif;
  transition: all 0.5s ease;
}

/* Classic Dark Mode */
.dark-mode {
  background-color: #1d1d1d;
  color: #ffffff;
}

/* Classic Light Mode */
.light-mode {
  background-color: #ffffff;
  color: #1d1d1d;
}

/* Chess Theme */
.chess-theme {
  background-color: #E9BE77;
  background-image: linear-gradient(45deg, #A87B51 25%, transparent 25%),
                    linear-gradient(-45deg, #A87B51 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, #A87B51 75%),
                    linear-gradient(-45deg, transparent 75%, #A87B51 75%);
  background-size: 80px 80px;
  background-position: 0 0, 0 40px, 40px -40px, -40px 0;
  color: #fff;
  cursor: url('./assets/icons/bishop.png') 12 12, auto; 
}

/* Chess Theme Navigation Bar */
.chess-theme nav {
  background-image: linear-gradient(45deg, #000 25%, transparent 25%),
                    linear-gradient(-45deg, #000 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, #000 75%),
                    linear-gradient(-45deg, transparent 75%, #000 75%);
  background-size: 40px 40px;
  background-position: 0 0, 0 20px, 20px -20px, -20px 0;
}

.chess-theme nav a {
  color: #fff; 
}

.chess-theme nav a:hover {
  color: #ffa500; 
}

/* Chess Theme Sound Effect Class */
.chess-sound {
  cursor: pointer;
}

/* Futuristic Game Theme */
.futuristic-theme {
  background-color: #000;
  color: #39ff14;
  font-family: 'Courier New', Courier, monospace;
}

.futuristic-theme h1, .futuristic-theme h2, .futuristic-theme h3 {
  text-shadow: 0 0 5px #39ff14, 0 0 10px #39ff14;
  animation: glitch 1.5s infinite;
}

body.futuristic-theme h1, body.futuristic-theme h2, body.futuristic-theme h3,
body.futuristic-theme nav a, body.futuristic-theme footer a {
  font-family: 'nasalization', sans-serif; 
  color: #00FF41;
  text-shadow: 0 0 10px #00FF41, 0 0 20px #00FF41, 0 0 30px #00FF41;
}

@keyframes glitch {
  0% { text-shadow: 2px 2px 0 #39ff14; }
  20% { text-shadow: -2px -2px 0 #39ff14; }
  40% { text-shadow: 2px -2px 0 #39ff14; }
  60% { text-shadow: -2px 2px 0 #39ff14; }
  80% { text-shadow: 2px 2px 0 #39ff14; }
  100% { text-shadow: -2px -2px 0 #39ff14; }
}

nav {
  position: sticky;
  top: 0;
  background-color: #333;
  padding: 10px;
  z-index: 1000;
  transition: background-color 0.5s ease;
}

nav a {
  color: #ffffff;
  margin: 0 15px;
  font-size: 1.1rem;
}

nav a:hover {
  color: #ffa500;
}

button {
  font-family: 'Azonix', sans-serif;
  background-color: #ff6347;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: #ff4500;
  transform: scale(1.05);
}

/* Contact Form Styling */
.contact-form {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: 20px auto;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #555;
  border-radius: 5px;
  background-color: #222;
  color: #fff;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #aaa;
}

.contact-form button {
  width: 100%;
  padding: 12px;
  background-color: #ff6347;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #ff4500;
}

/* Footer Enhancements */
footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #444;
}

footer a {
  color: #ffa500;
  margin: 0 10px;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #ff4500;
}

/* Code Styling */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Styling for Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  color: #ff6347;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

/* Link Styling */
a {
  color: #ffa500;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #ff4500;
}

/* Loading Spinner Styling */
.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffa500;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 50px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Dark Mode Toggle Button */
.dark-mode-toggle {
  position: fixed;
  top: 15px;
  right: 15px;
  background-color: #444;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.dark-mode-toggle:hover {
  background-color: #666;
}

nav a, footer a, .contact-form input, .contact-form textarea {
  font-family: 'Poppins', sans-serif;
}

/* Theme Selector Buttons */
.theme-selector {
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.theme-selector button {
  font-family: 'Poppins', sans-serif;
  background-color: #444;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.theme-selector button:hover {
  background-color: #666;
}
